import React, { Component, ErrorInfo, ReactNode } from "react";
import { Box, Loader, Page, WixDesignSystemProvider } from "@wix/design-system";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
          <Page>
            <Page.Content>
              <Box
                padding="10px"
                align="center"
                verticalAlign="middle"
                height={"90vh"}
                direction="vertical"
              >
                <Loader status="error" text="Something went wrong. Please refresh this page or contact support." />
              </Box>
            </Page.Content>
          </Page>
        </WixDesignSystemProvider>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
